<template>
    <div>
        <div class="carousel-container half-width">
            <div class="jcarousel" ref="slideshow">
                <div class="slides">
                    <template v-for="(slide, i) in slides">
                        <figure :key="i" :id="`slide-${i}`" @click="handleClick(slide.value.image)" class="slide-snapper">
                            <div class="img-container">
                                <img :src="url(slide.value.image)" :alt="slide.value.image.alt_text">
                                <div class="overlay">
                                    <div class="overlay-content">
                                        <IconMagnify />
                                    </div>
                                </div>
                            </div>
                            <figcaption v-if="slide.value.image.display_title || slide.value.image.caption">
                                <h3 v-if="slide.value.image.display_title">{{ slide.value.image.display_title }}</h3>
                                <p>{{ slide.value.image.caption }}</p>
                            </figcaption>
                        </figure>
                    </template>
                </div>
            </div>
            <div class="controls">
                <button class="jcarousel-prev">
                    <PrevIcon />
                </button>
                <button class="jcarousel-next">
                    <NextIcon />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import NextIcon from "@/assets/svg/slideshow-arrow-right.svg"
import PrevIcon from "@/assets/svg/slideshow-arrow-left.svg"
import Modal from "@/components/Modal"
import IconMagnify from "@/assets/svg/icon-gallery-hover.svg";

import { openInNewWindow } from "@/utils/url";

export default {
    name: 'CarouselBlock',
    props: {
        slides: Array
    },
    components: {
        NextIcon,
        PrevIcon,
        Modal,
        IconMagnify,
    },
    methods: {
        url(image) {
            if (image.error) return '';
            return image.url;
        },
        urlOriginal(image) {
            if (image.error) return '';
            return image.original_url;
        },
        handleClick(image) {
            openInNewWindow(this.urlOriginal(image));
        },
    },
    mounted() {
        // trigger carousel build in carousel.js
        if(this.$refs.slideshow) {
            const event = new Event("build", { bubbles: true });
            this.$refs.slideshow.dispatchEvent(event);
        }
    }
}
</script>

<style lang="scss" scoped>
// in carousel.scss</style>
