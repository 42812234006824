export const state = () => ({
    coords: null,
    layers: {},
    layerDefault: null,
    zoomDefault: null,
    boundaryTL: null,
    boundaryBR: null,
    zoomLimit: null,
    layerColors: {},
    activeGroups: [],
    showKey: false,
})

export const getters = {
    zoomLimit: state => state.zoomLimit,
    coords: state => state.coords,
    layers: state => state.layers,
    layerDefault: state => state.layerDefault,
    zoomDefault: state => state.zoomDefault,
    boundaryTL: state => state.boundaryTL,
    boundaryBR: state => state.boundaryBR,
    layerColors: state => state.layerColors,
    activeGroups: state => state.activeGroups,
    showKey: state => state.showKey,
};

export const actions = {
    /**
     * Retrieve layers
     * @param {*} param0
     * @param {*} slug
     */
    fetchLayers({commit, state, rootState}) {
        let layers = {
            satelite: L.tileLayer.bing({
                bingMapsKey: 'AqjlxGphqMSqMU6uoNGoHVwN5ecjloFF6sSUYxvqzblOhC51I4gb8dQh7TwY5dpp',
                imagerySet: 'Aerial',
                maxZoom: 19,
            }),
            street: L.tileLayer.bing({
                bingMapsKey: 'AqjlxGphqMSqMU6uoNGoHVwN5ecjloFF6sSUYxvqzblOhC51I4gb8dQh7TwY5dpp',
                imagerySet: 'Road',
                maxZoom: 19,
            })
        }

        switch(rootState.global.site){
            case 'corinth':
                layers.labels = [
                    'Greek',
                    'Hellenistic',
                    'Roman'
                ]
                layers.Greek = L.tileLayer('https://aml.azureedge.net/maps/c-greek/{z}/{x}/{y}.png', {
                    attribution: "(CORINTH - GREEK MAP) &copy; Parrhasian Heritage Foundation",
                    minZoom: 18,
                    maxZoom: 19,
                    tms: false
                })
                layers.Hellenistic = L.tileLayer('https://aml.azureedge.net/maps/corinith-hellenistic-2/{z}/{x}/{y}.png', {
                    attribution: "(CORINTH - HELLENISTIC MAP) &copy; Parrhasian Heritage Foundation",
                    minZoom: 18,
                    maxZoom: 19,
                    tms: false
                }),
                layers.Roman = L.tileLayer('https://aml.azureedge.net/maps/c-roman_3-14-23/{z}/{x}/{y}.png', {
                    attribution: "(CORINTH - ROMAN MAP) &copy; Parrhasian Heritage Foundation",
                    minZoom: 8,
                    maxZoom: 19,
                    tms: false
                })
                break;
            case 'mt-lykaion':
                layers.labels = [
                    'Mt-Lykaion',
                ]
                layers['Mt-Lykaion'] = L.tileLayer('https://aml.azureedge.net/maps/mtlyk_11-18-22_2/{z}/{x}/{y}.png', {
                    attribution: "(MT LYK - MAIN MAP) &copy; Parrhasian Heritage Foundation",
                    minZoom: 16,
                    maxZoom: 18,
                    tms: false
                })
                break;
            case 'parrhasian-heritage-park':
                layers.labels = [
                    'Parrhasian-Heritage-Park',
                ]
                // parr-park_01-25-24
                layers['Parrhasian-Heritage-Park'] = L.tileLayer('https://amlwww.blob.core.windows.net/maps/php-map-240126/{z}/{x}/{y}.png', {
                    attribution: "(PARK - MAIN MAP) &copy; Parrhasian Heritage Foundation",
                    minZoom: 11,
                    maxZoom: 14,
                    tms: false
                })
        }
        commit('setLayers', layers)
    },

    /**
     * Set initial map layer to display
     */
    layerDefault({commit, state, rootState}) {
        switch(rootState.global.site){
            case 'corinth':
                commit('setDefaultLayer', 'Roman')
                break;
            case 'mt-lykaion':
                commit('setDefaultLayer', 'Mt-Lykaion')
                break;
            case 'parrhasian-heritage-park':
                commit('setDefaultLayer', 'Parrhasian-Heritage-Park')
        }  
    },

    /**
     * Set colors and layers key
     */
    setMapColorKey({commit}, data) {
        let groups = data.reduce((groupMap, record) => {
            record.groups.forEach(gr => {
                 if (Object.keys(groupMap).indexOf(gr.name) == -1) {
                    groupMap[gr.name.replaceAll(' ', '-')] = gr.marker_color
                }
            })
            return groupMap
        }, {})

        commit('setColorKey', groups)
    },
    /**
     * Set active groups
     */
    setActiveGroups({commit}, data) {
        let groups = data.reduce((groupList, record) => {
            record.groups.forEach(gr => {
                let slug = gr.name.replaceAll(' ', '-')
                if (!groupList.find(g => g.slug == slug)) {
                    if (gr.active) groupList.push({
                        name: gr.name,
                        slug: slug
                    })
                }
            })
            return groupList
        }, [])

        commit('setActiveGroupsList', groups)
    },

    /**
     * Set map coordinates: boundaries, center
     */
    mapCoords({commit, state, rootState}) {
        switch(rootState.global.site){
            case 'corinth':
                let corinthCoords = {
                    bTL: [37.907791, 22.874824],
                    bBR: [37.904087, 22.881888],
                    center: [37.905407,22.879686],
                }
                commit('setCoords', corinthCoords)
                break;
            case 'mt-lykaion':
                let lykCoords = {
                    bTL: [37.452450, 21.984747],
                    bBR: [37.442697, 22.000869],
                    center: [37.4475735, 21.992808],
             }
                commit('setCoords', lykCoords)
                break;
            case 'parrhasian-heritage-park':
                let parkCoords = {
                    bTL: [37.513607, 21.702458],
                    bBR: [37.267230, 22.196272],
                    center: [37.3904185, 21.929365],
                }
                commit('setCoords', parkCoords)
        }
    },
    /**
     * Set zoom min and max
     */
    zoomLimit({commit, state, rootState}) {
        switch(rootState.global.site){
            case 'corinth':
                let corinthZoom = {
                    min: 18,
                    max: 19
                }
                commit('setZoomLimit', corinthZoom)
                break;
            case 'mt-lykaion':
                let lykZoom = {
                    min: 16,
                    max: 18
             }
                commit('setZoomLimit', lykZoom)
                break;
            case 'parrhasian-heritage-park':
                let parkZoom = {
                    min: 11.7,
                    max: 14,
                }
                commit('setZoomLimit', parkZoom)
        }
    },
    /**
     * Set initial zoom level
     */
     zoomDefault({commit, state, rootState}) {
        switch(rootState.global.site){
            case 'corinth':
                commit('setDefaultZoom', 19)
                break;
            case 'mt-lykaion':
                commit('setDefaultZoom', 17)
                break;
            case 'parrhasian-heritage-park':
                commit('setDefaultZoom', 11.7)
        }  
    },
    /**
     * Set toggle to show map key
     */
    setShowKey({commit}, data) {
        commit('setKey', data)
    }
};

export const mutations = {
    setLayers(state, layers) {
        state.layers = layers
    },
    setDefaultLayer(state, layer) {
        state.layerDefault = layer
    },
    setCoords(state, coords) {
        state.coords = coords
    },
    setZoomLimit(state, zoomLimit) {
        state.zoomLimit = zoomLimit
    },
    setDefaultZoom(state, zoom) {
        state.zoomDefault = zoom
    },
    setColorKey(state, data) {
        state.layerColors = data
    },
    setActiveGroupsList(state, data) {
        state.activeGroups = data
    },
    setKey(state, data) {
        state.showKey = data
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
