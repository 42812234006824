<template>
    <div class="relative">
        <figure>
            <img @click="handleClick" :src="src">
            <figcaption>{{ caption }}</figcaption>
        </figure>
        <!-- <Modal v-show="isModalOpen" :is-open="isModalOpen" @close="isModalOpen = false">
            <div class="inner single">
                <figure>
                    <div class="img padding">
                        <img :src="this.block.value.image.large_url" :alt="altText">
                    </div>
                    <figcaption v-if="caption" class="figca">
                        <p v-if="caption" class="caption">{{ caption }}</p>
                    </figcaption>
                </figure>
            </div>
        </Modal> -->
    </div>
</template>

<script>
import LoadingIndicator from '@/components/LoadingIndicator'
import Modal from "@/components/Modal"

import { openInNewWindow } from "@/utils/url";

export default {
    name: 'ImageBlock',
    components: {
        LoadingIndicator,
        Modal,
    },
    props: {
        block: Object
    },
    data() {
        return {
            isModalOpen: false,
        }
    },
    computed: {
        src() {
            let src = '';
            if (this.block.value.image_url) {
                src = this.block.value.image_url;
            } else if (this.block.value.image.url) {
                src = this.block.value.image.url;
            }
            return src;
        },
         
        caption() {
            let caption = ''
            if (this.block.value.image_caption) {
                caption = this.block.value.image_caption
            } else if (this.block.value.image.caption) {
                caption = this.block.value.image.caption
            }
            return caption;
        }
    },
    methods: {
        urlOriginal(image) {
            if (image.error) return '';
            return image.original_url;
        },
        handleClick() {
            openInNewWindow(this.urlOriginal(this.block.value.image));
        },
    },
}
</script>

<style lang="scss" scoped>
figure {
    margin: 0;
    padding: 0;

    img {
        max-width: 100%;
    }
    &:hover {
        cursor: pointer;
    }
}
</style>
